<template>
  <div class="userAdd">
    <a-spin :spinning="isSpin">
      <a-form
        :model="formState"
        name="userAdd"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        autocomplete="off"
        @finish="onSubmit"
      >
        <a-form-item
          label="用户名"
          name="username"
          :rules="[{ required: true, message: '请输入用户名' }]"
        >
          <a-input v-model:value="formState.username"></a-input>
        </a-form-item>
        <a-form-item
          label="密码"
          name="password"
          :rules="[{ required: true, message: '请输入密码' }]"
        >
          <a-input v-model:value="formState.password"></a-input>
        </a-form-item>
        <a-form-item
          label="密码确认"
          name="password2"
          :rules="[{ required: true, message: '请再次输入密码' }]"
        >
          <a-input v-model:value="formState.password2"></a-input>
        </a-form-item>
        <a-form-item
          label="用户说明"
          name="description"
          :rules="[{ required: true, message: '请输入用户说明' }]"
        >
          <a-input v-model:value="formState.description"></a-input>
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 3, span: 16 }">
          <a-button type="primary" html-type="submit">提交</a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import * as systemApi from "@/service/modules/system";
export default {
  data() {
    return {
      formState: {
        username: '',
        password: '',
        password2: '',
        description: '',
      },
      isSpin: false,
    };
  },
  mounted() {
  },
  methods: {
    onSubmit(value) {
     // console.log(value);
      let postData = {
        username: value.username,
        password: value.password,
        password2: value.password2,
        description: value.description,
      };
      systemApi.userAdd(postData).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.$closePage(this.$router.currentRoute.value, { path: '/system/systemRoot/user' });
        }
      });
    },

  },
};
</script>
<style lang="less" scoped></style>